export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.server) return;

  const token = localStorage.getItem('token');

  if (!token) {
    return navigateTo('/login');
  }

  return;
});
